import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface PaymentPartProps {
  name: string;
  caption: string;
  subcaption: string;
  isMostPopular?: boolean;
  isActive?: boolean;
  onClick?: () => void;
}

export const PaymentPart = ({
  name,
  caption,
  subcaption,
  isMostPopular,
  isActive,
  onClick,
}: PaymentPartProps) => {
  const { t } = useTranslation();
  const backgroudColor = isActive
    ? "bg-gradient-to-b from-primary-100 via-[#9E87CF] via-[#B49FE1] to-[#9C8CBD]"
    : "group-active:bg-primary-600 group-hover:bg-primary-600";

  const textColor = isActive ? "text-light-100" : "text-primary-100";

  const border = isActive ? "border-none" : "border-2";

  return (
    <button type="button" onClick={onClick} className="flex gap-2.5 relative group">
      <div
        className={classNames(
          "px-2.5 h-19.5 min-w-28.5 border-primary-100 text-h3 rounded-lg flex items-center justify-center ",
          backgroudColor,
          textColor,
          border
        )}
      >
        <p>{name}</p>
      </div>
      <div
        className={classNames(
          "grow px-2.5 h-19.5 min-w-28.5 border-primary-100 text-body-2 rounded-lg flex items-center justify-center",
          textColor,
          backgroudColor,
          border
        )}
      >
        <p>
          {caption}{" "}
          <span
            className={classNames(
              "text-subtitle-2",
              isActive ? "text-primary-900" : "text-primary-500"
            )}
          >
            {subcaption}
          </span>
        </p>
      </div>
      {isMostPopular && (
        <span className="absolute bg-gradient-to-b from-primary-100 to-primary-300 -top-2.5 right-7.5 text-light-100 px-2.5 py-1.5 text-subtitle-3 rounded-lg">
          {t("common.most_popular")}
        </span>
      )}
    </button>
  );
};

export const uploadFile = async (
  file: File,
  setFileCallback: (url: string) => void,
  setIsLoading: (bool: boolean) => void = () => {}
) => {
  try {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    const uploadResponse = await fetch(import.meta.env.VITE_BOT_API_URL + "upload", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (!uploadResponse) {
      throw new Error("Upload request failed");
    }

    const { url } = uploadResponse;
    setFileCallback(url);
  } catch (error) {
    console.log(error);
  } finally {
    setIsLoading(false);
  }
};

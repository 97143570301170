import { useCallback, useEffect, useState } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import { BackButtonProvider } from "../../providers/BackButtonProvider";
import { useTranslation } from "react-i18next";
import CSVReader from "react-csv-reader";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { FilledButton } from "../primitives/buttons/FilledButton";
import { ListWrapper } from "../primitives/wrappers/ListWrapper";
import { DownloadIcon, PaperClipIcon, PlusIcon, TrashIcon } from "../../assets/icons/icons";
import { LOCALES } from "../../common/locales";
import { useQuery } from "@tanstack/react-query";

import {
  addManagerQuery,
  deleteManagerQuery,
  getFilterUsersQuery,
  getManagersQuery,
} from "../../services/web-app";
import classNames from "classnames";
import { PostsSearch } from "./Inputs/PostsSearch";
import { GiveSubscription } from "./Inputs/GiveSubscription";
import { shortcutUsername } from "../../utils/getUsername";

export interface IUser {
  id: number;
  username?: string;
  first_name: string;
}

export const AdminPanel = () => {
  const { t } = useTranslation();
  const { tg, sendData } = useTelegram();

  const [userId, setUserId] = useState<number | null>(null);
  const [startDataMessage, setStartDataMessage] = useState("");
  const [bannedSearchTerm, setBannedSearchTerm] = useState("");
  const [unBannedSearchTerm, setUnbannedSearchTerm] = useState("");
  const [_, setError] = useState("");
  const [locale, setLocale] = useState<LOCALES>(LOCALES.ru);
  const [managers, setManagers] = useState<string[]>([]);
  const [newManager, setNewManager] = useState<string>("");
  const [isOpenNewManager, setIsOpenNewManager] = useState<boolean>(false);
  const [managersLoading, setManagersLoading] = useState<boolean>(false);

  const getStopWordsFile = async () => {
    await sendData({ type: "get_stop_word" });
    tg.close();
  };

  const addStopWords = useCallback(
    (stopWords: string[]) => {
      sendData({ stopWords, type: "add_stop_words" });
      tg.close();
    },
    [tg, sendData]
  );

  const getBannedUsers = useCallback(async (): Promise<IUser[]> => {
    try {
      if (bannedSearchTerm.length < 1) return [];

      setUserId(null);
      const res = await getFilterUsersQuery(shortcutUsername(bannedSearchTerm), true);

      if (res.ok) {
        const data = await res.json();
        return data;
      }

      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [bannedSearchTerm]);

  const getUnBannedUsers = useCallback(async (): Promise<IUser[]> => {
    try {
      if (unBannedSearchTerm.length < 1) return [];

      setUserId(null);
      const res = await getFilterUsersQuery(shortcutUsername(unBannedSearchTerm), false);

      if (res.ok) {
        const data = await res.json();
        return data;
      }

      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [unBannedSearchTerm]);

  const { data: unBannedUsers, isLoading: unBannedUsersLoading } = useQuery({
    queryKey: ["unBannedUsers", unBannedSearchTerm],
    queryFn: getUnBannedUsers,
    enabled: !!shortcutUsername(unBannedSearchTerm),
  });

  const { data: bannedUsers, isLoading: bannedUsersLoading } = useQuery({
    queryKey: ["bannedUsers", bannedSearchTerm],
    queryFn: getBannedUsers,
    enabled: !!shortcutUsername(bannedSearchTerm),
  });

  const unBanUser = useCallback(async (id: number) => {
    try {
      await sendData({ userId: id, type: "unban_user" }, false);
      setBannedSearchTerm("");
    } catch (error) {
      console.log(error);
    }
  }, []);

  const banUser = useCallback(async () => {
    try {
      if (!userId) return;
      await sendData({ userId, type: "ban_user" }, false);
      setUnbannedSearchTerm("");
      setUserId(null);
    } catch (error) {
      console.log(error);
    }
  }, [userId]);

  const handleUserSelection = (userId: number) => {
    setUserId(userId);
  };

  const getUserName = (user: IUser | undefined) => {
    if (!user) return "";
    return user.username || user.first_name;
  };

  const changeStartMessage = useCallback(() => {
    if (!startDataMessage.trim() || !locale) return;
    sendData({ locale, startDataMessage, type: "change_start_message" });
  }, [startDataMessage, sendData, locale]);

  const getManagers = useCallback(async () => {
    try {
      setManagersLoading(true);
      setError("");
      const response = await getManagersQuery();
      if (!response.ok) throw new Error("Failed to fetch managers");
      const data = await response.json();
      setManagers(data);
    } catch (error) {
      console.log(error);
      error instanceof Error && setError(error.message);
    } finally {
      setManagersLoading(false);
    }
  }, []);

  const deleteManager = useCallback(async (id: string) => {
    try {
      setManagersLoading(true);
      setError("");
      const response = await deleteManagerQuery(id);
      if (!response.ok) throw new Error("Failed to delete managers");
      const data = await response.json();
      setManagers(data);
    } catch (error) {
      console.log(error);
      error instanceof Error && setError(error.message);
    } finally {
      setManagersLoading(false);
    }
  }, []);

  const addManager = useCallback(async () => {
    try {
      if (!newManager || managers.includes(newManager)) return;
      setManagersLoading(true);
      setError("");
      const response = await addManagerQuery(newManager);
      if (!response.ok) throw new Error("Failed to delete managers");
      const data = await response.json();
      setManagers(data);
      setIsOpenNewManager(false);
      setNewManager("");
    } catch (error) {
      console.log(error);
      error instanceof Error && setError(error.message);
    } finally {
      setManagersLoading(false);
    }
  }, [newManager, managers]);

  useEffect(() => {
    if (!userId) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
      tg.MainButton.setText(t("admin_panel.ban_user"));
    }
  }, [userId, tg, t]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", banUser);
    return () => {
      tg.offEvent("mainButtonClicked", banUser);
    };
  }, [banUser, tg]);

  useEffect(() => {
    getManagers();
  }, [getManagers]);

  return (
    <BackButtonProvider>
      <section>
        <MainHeadline>{t("admin_panel.headline")}</MainHeadline>

        <GiveSubscription />

        <ListWrapper classes="mb-6.5">
          <h2 className="text-dark-200 text-h4 text-left">{t("admin_panel.ban_user")}</h2>
          <input
            className="grow text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
            type="text"
            placeholder={t("admin_panel.toggle_input_placeholder")}
            value={unBannedSearchTerm}
            onChange={({ target }) => setUnbannedSearchTerm(target.value)}
          />
          <ul className="flex flex-col gap-1.5">
            {unBannedSearchTerm &&
              unBannedUsers?.map((user) => (
                <li
                  className={classNames(
                    "grow rounded-xl px-3 py-2.5 cursor-pointer outline-none",
                    userId === user.id
                      ? "bg-primary-100 bg-gradient-to-b from-primary-100 to-primary-300 text-light-100"
                      : "bg-light-200 text-primary-100 "
                  )}
                  key={user.id}
                  onClick={() => handleUserSelection(user.id)}
                >
                  {getUserName(user)}
                </li>
              ))}
          </ul>
          {(unBannedUsersLoading || "") && <p>{t("common.loading")}</p>}
        </ListWrapper>
        <ListWrapper classes="mb-6.5">
          <h2 className="text-dark-200 text-h4 text-left">{t("admin_panel.unban_user")}</h2>
          <input
            className="grow text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
            type="text"
            placeholder={t("admin_panel.toggle_input_placeholder")}
            value={bannedSearchTerm}
            onChange={({ target }) => setBannedSearchTerm(target.value)}
          />
          <ul className="flex flex-col gap-1.5">
            {bannedSearchTerm &&
              bannedUsers?.map((user) => (
                <li key={user.id} className="flex justify-between">
                  <p className="text-secondary-button text-dark-200">{getUserName(user)}</p>
                  <div className="flex gap-5.5">
                    <button type="button" onClick={() => unBanUser(user.id)}>
                      <TrashIcon />
                    </button>
                  </div>
                </li>
              ))}
          </ul>
          {(bannedUsersLoading || "") && <p>{t("common.loading")}</p>}
        </ListWrapper>

        <PostsSearch />

        <ListWrapper classes="mb-10">
          <h2 className="text-dark-200 text-h4 text-left">
            {t("admin_panel.update_start_message")}
          </h2>
          <input
            className="grow text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
            onChange={({ target }) => setStartDataMessage(target.value)}
            value={startDataMessage}
            type="text"
            placeholder={t("admin_panel.start_message_placeholder")}
          />

          <select
            className="text-primary-100 bg-transparent border-primary-100 rounded-lg border px-3 py-2.5 cursor-pointer outline-none"
            onChange={({ target }) => setLocale(target.value as LOCALES)}
            name="locale"
            id="locale"
          >
            {Object.values(LOCALES).map((locale) => (
              <option key={locale} value={locale}>
                {locale}
              </option>
            ))}
          </select>
          {startDataMessage.trim() && (
            <FilledButton type="button" onClick={changeStartMessage}>
              {t("common.save")}
            </FilledButton>
          )}
        </ListWrapper>

        <ListWrapper classes="mb-10">
          <h2 className="text-dark-200 text-h4 text-left">{t("admin_panel.add_stop_word")}</h2>
          <div>
            <button className="flex items-center text-h4 text-primary-100 mb-2.5" type="button">
              <PaperClipIcon />{" "}
              <label htmlFor="stopWordsFile">{t("admin_panel.attach_file")}</label>
              <CSVReader
                cssClass="hidden"
                inputId="stopWordsFile"
                onFileLoaded={(data) => addStopWords(data.flat())}
              />
            </button>
            <div className="flex justify-between pl-6">
              <p className="text-secondary-button text-dark-200">stop words_1.cvs</p>
              <div className="flex gap-5.5">
                <button type="button" onClick={getStopWordsFile}>
                  <DownloadIcon />
                </button>
              </div>
            </div>
          </div>
        </ListWrapper>
        <ListWrapper classes="mb-10">
          <h2 className="text-dark-200 text-h4 text-left">{t("admin_panel.your_managers")}</h2>
          <div>
            {isOpenNewManager ? (
              <div className="flex gap-3">
                <input
                  className="grow mb-2.5 text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
                  onChange={({ target }) => setNewManager(target.value)}
                  value={newManager}
                  type="text"
                  placeholder={t("admin_panel.manager_username_placeholder")}
                />
                <button type="button" onClick={addManager}>
                  <PlusIcon />
                </button>
              </div>
            ) : (
              <button
                onClick={() => setIsOpenNewManager(true)}
                className="flex items-center text-h4 text-primary-100 mb-2.5"
                type="button"
              >
                <PlusIcon />
                <p>{t("admin_panel.add_manager")}</p>
              </button>
            )}
            {(managersLoading || "") && <p>{t("common.loading")}</p>}

            <ul className="pl-6 flex flex-col gap-2.5">
              {managers?.map((username) => (
                <li key={username} className="flex justify-between">
                  <p className="text-secondary-button text-dark-200">@{username}</p>
                  <div className="flex gap-5.5">
                    <button type="button" onClick={() => deleteManager(username)}>
                      <TrashIcon />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </ListWrapper>
      </section>
    </BackButtonProvider>
  );
};

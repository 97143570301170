import { ButtonProps } from "../../../types/button-props";

interface ContainedButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const ContainedButton = ({ children, ...props }: ContainedButtonProps) => {
  return (
    <button
      type="button"
      className="bg-transparent text-secondary-button w-full text-dark-100 active:text-dark-200 hover:text-dark-200 text-center py-3.5 outline-none"
      {...props}
    >
      {children}
    </button>
  );
};

import classNames from "classnames";
import { ButtonProps } from "../../../types/button-props";

interface SecondaryButtonProps extends ButtonProps {
  children: React.ReactNode;
  classes?: string;
}

export const SecondaryButton = ({ children, classes, ...props }: SecondaryButtonProps) => {
  return (
    <button
      type="button"
      className={classNames(
        "bg-light-200 text-primary-100 text-secondary-button rounded-lg w-full text-center py-3.5 outline-none active:bg-primary-400 hover:bg-primary-400",
        classes
      )}
      {...props}
    >
      {children}
    </button>
  );
};

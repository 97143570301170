import { useTranslation } from "react-i18next";
import { FilledButton } from "../buttons/FilledButton";
import { SuccessIcon, XCrossIcon } from "../../../assets/icons/icons";
import { useNotificationContext } from "../../../hooks/useNotificationContext";

export const SuccessNotification = () => {
  const { t } = useTranslation();
  const { closeNotifications, success } = useNotificationContext();

  if (!success) return null;

  return (
    <div className="top-0 left-0 fixed z-50 w-screen h-screen ">
      <div onClick={closeNotifications} className="w-full h-full absolute bg-dark-blur"></div>
      <div className="bg-light-100 absolute bottom-0 w-full px-4 pt-4 pb-6.5 rounded-t-xl">
        <div className="flex flex-col gap-1.5 items-center mb-5">
          <button onClick={closeNotifications} className="absolute top-2.5 right-4" type="button">
            <XCrossIcon />
          </button>
          <SuccessIcon />
          <h4 className="text-dark-200 text-h4">{t("notifications.success")}</h4>
          <div>
            <p className="text-body-1 text-secondary-400  text-center">
              {t("notifications.success_action_1")}
            </p>
            <p className="text-body-1 text-secondary-400  text-center">
              {t("notifications.success_action_2")}
            </p>
          </div>
        </div>
        <FilledButton onClick={closeNotifications}>{t("notifications.continue")}</FilledButton>
      </div>
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { Link } from "react-router-dom";
import { FilledButton } from "../primitives/buttons/FilledButton";
import { useCallback, useEffect, useState } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import { getManagerLink } from "../../services/web-app";

interface BannedOverlayProps {
  isOpen: boolean;
}

export const BannedOverlay = ({ isOpen }: BannedOverlayProps) => {
  const { t } = useTranslation();
  const { tg } = useTelegram();

  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");

  const getLink = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getManagerLink();
      if (!response.ok) throw new Error("Failed to fetch link");
      const data = await response.json();
      setLink(data.link);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      tg.MainButton.hide();
      getLink();
    }
  }, [isOpen, tg]);

  if (!isOpen) return null;

  return (
    <div className="top-0 left-0 fixed z-50 w-screen h-screen bg-light-300 text-center">
      <section className="px-4 pb-13.5 flex flex-col justify-between h-full">
        <MainHeadline>{t("banned_overlay.headline")}</MainHeadline>
        <div>
          <p className="text-subtitle-1 text-secondary-400">{t("banned_overlay.message_1")}</p>
          <p className="text-subtitle-1 text-secondary-400 mb-7.5">
            {t("banned_overlay.message_2")}
          </p>
          <div className="flex flex-col gap-2">
            <Link to={link}>
              <FilledButton disabled={isLoading}>
                {t("banned_overlay.contact_support")}
              </FilledButton>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

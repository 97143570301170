import { memo } from "react";
import { LgIconButton } from "../primitives/buttons/LgIconButton";
import { ImageIcon } from "../../assets/icons/icons";

interface AcceptPhotoProps {
  image: string;
  video: string;
  setImageFile: (file: File | undefined) => void;
}

export const AcceptPhoto = memo(
  ({ image, video, setImageFile }: AcceptPhotoProps) => {
    if (video) return null;

    return (
      <>
        {image ? (
          <img src={image} alt="PostImage" />
        ) : (
          <LgIconButton htmlFor="postImage">
            <ImageIcon />
            <input
              style={{ display: "none" }}
              id="postImage"
              type="file"
              multiple={false}
              onChange={({ target }) => setImageFile(target.files?.[0] || undefined)}
              accept="image/png, image/jpeg, image/jpg, image/gif, image/webp"
            />
          </LgIconButton>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.image === nextProps.image && prevProps.video === nextProps.video
);

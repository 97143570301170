import { useCallback } from "react";
import { queryWebApp } from "../services/web-app";

const tg = window.Telegram.WebApp;

export function useTelegram() {
  const onClose = () => {
    tg.close();
  };

  const onToggleButton = () => {
    if (tg.MainButton.isVisible) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  };

  const sendData = useCallback(async (data: Record<string, any>, close = true) => {
    try {
      if (!tg?.initDataUnsafe?.user) {
        tg.sendData(data);
        return;
      }

      await queryWebApp(data);
      close && tg.close();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return {
    sendData,
    onClose,
    onToggleButton,
    tg,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
  };
}

// import { useDebounce } from "../../../hooks/useDebounce";
import { useQuery } from "@tanstack/react-query";
import { getFilterPostsQuery } from "../../../services/web-app";
import { useCallback, useState } from "react";
import { IUser } from "../AdminPanel";
import { ListWrapper } from "../../primitives/wrappers/ListWrapper";
import { useTranslation } from "react-i18next";

interface IPost {
  _id: string;
  text: string;
  createdAt: string;
  userId: IUser;
}

export const PostsSearch = () => {
  const { t } = useTranslation();

  const [lowerDate] = useState<string>("");
  const [upperDate] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  // const [debouncedUnBannedSearchTerm] = useDebounce([unBannedSearchTerm], 300);

  const searchEnabled = !!(searchTerm || lowerDate || upperDate);

  const getPostsInfo = useCallback(async (): Promise<IPost[]> => {
    try {
      const res = await getFilterPostsQuery(searchTerm);

      if (res.ok) {
        const data = await res.json();
        return data;
      }

      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [lowerDate, upperDate, searchTerm]);

  const { data: posts, isLoading } = useQuery({
    queryKey: ["posts", searchTerm, lowerDate, upperDate],
    queryFn: getPostsInfo,
    enabled: searchEnabled,
  });

  const getTimeString = (date: string) => {
    try {
      const time = new Date(date).toLocaleTimeString();
      const dateStr = new Date(date).toLocaleDateString();
      return `${dateStr} ${time}`;
    } catch (error) {
      return date;
    }
  };

  const getPostText = (text: string) => {
    const POST_MAX_LENGTH = 100;
    return text.length > POST_MAX_LENGTH ? text.slice(0, POST_MAX_LENGTH) + "..." : text;
  };

  return (
    <ListWrapper classes="mb-6.5">
      <h2 className="text-dark-200 text-h4 text-left">{t("admin_panel.get_latest_posts")}</h2>
      <input
        className="grow text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
        type="text"
        placeholder={t("admin_panel.toggle_input_placeholder")}
        value={searchTerm}
        onChange={({ target }) => setSearchTerm(target.value)}
      />
      <ul className="flex flex-col gap-1.5">
        {posts?.map((post) => (
          <li
            key={post._id}
            className="border-b border-gray-200 py-4 text-left flex flex-col gap-1"
          >
            <p className="text-base font-medium text-dark-100">{t("admin_panel.post_text")}</p>
            <p className="text-sm font-medium text-secondary-400">{getPostText(post.text)}</p>
            <p className="text-sm text-secondary-200">
              {t("admin_panel.created_at")}: {getTimeString(post.createdAt)}
            </p>
            {post?.userId && (
              <div>
                <p className="text-base font-medium text-primary-100">
                  {t("admin_panel.name")} {post?.userId?.first_name}
                </p>
                {post?.userId?.username && (
                  <p className="text-base font-medium text-primary-100">
                    {t("admin_panel.username")} @{post?.userId?.username}
                  </p>
                )}
                <p className="text-sm text-secondary-200">
                  {t("admin_panel.user_id")} {post.userId.id}
                </p>
              </div>
            )}
          </li>
        ))}
        {searchEnabled && posts?.length === 0 && <p>{t("admin_panel.no_posts_found")}</p>}
      </ul>
      {(isLoading || "") && <p>{t("common.loading")}</p>}
    </ListWrapper>
  );
};

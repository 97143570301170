export const DownloadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 16V17C4 17.7956 4.31607 18.5587 4.87868 19.1213C5.44129 19.6839 6.20435 20 7 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V16M16 12L12 16M12 16L8 12M12 16V4"
      stroke="#8569C1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const TrashIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5421 19.5159H15.4654C16.6255 19.5159 17.3713 18.8153 17.4316 17.6552L17.9363 6.80695H18.765C19.1492 6.80695 19.443 6.50562 19.443 6.12894C19.443 5.75227 19.1417 5.46599 18.765 5.46599H15.4202V4.33597C15.4202 3.17582 14.6819 2.4978 13.4238 2.4978H10.5611C9.30298 2.4978 8.5647 3.17582 8.5647 4.33597V5.46599H5.2349C4.85822 5.46599 4.55688 5.7598 4.55688 6.12894C4.55688 6.51315 4.85822 6.80695 5.2349 6.80695H6.06358L6.56832 17.6552C6.62859 18.8229 7.36687 19.5159 8.5421 19.5159ZM9.97346 4.40377C9.97346 4.01957 10.2447 3.77096 10.659 3.77096H13.3259C13.7402 3.77096 14.0114 4.01957 14.0114 4.40377V5.46599H9.97346V4.40377ZM8.69277 18.1674C8.27842 18.1674 7.97709 17.8586 7.95449 17.4141L7.44974 6.80695H16.5276L16.0379 17.4141C16.0228 17.8661 15.729 18.1674 15.2996 18.1674H8.69277ZM9.77759 16.9546C10.1015 16.9546 10.3049 16.7512 10.2974 16.4498L10.0714 8.56979C10.0639 8.26845 9.85292 8.07258 9.54405 8.07258C9.22764 8.07258 9.02424 8.27598 9.03177 8.57732L9.25778 16.4573C9.26531 16.7587 9.47625 16.9546 9.77759 16.9546ZM12 16.9546C12.3164 16.9546 12.5348 16.7587 12.5348 16.4573V8.57732C12.5348 8.27598 12.3164 8.07258 12 8.07258C11.6836 8.07258 11.4726 8.27598 11.4726 8.57732V16.4573C11.4726 16.7587 11.6836 16.9546 12 16.9546ZM14.2223 16.9621C14.5237 16.9621 14.7346 16.7587 14.7422 16.4573L14.9682 8.57732C14.9757 8.27598 14.7723 8.08011 14.4559 8.08011C14.147 8.08011 13.9361 8.27598 13.9285 8.57732L13.7025 16.4573C13.695 16.7512 13.8984 16.9621 14.2223 16.9621Z"
      fill="#8569C1"
    />
  </svg>
);

export const CrossIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316ZM11.1084 17.3457C11.3369 17.3457 11.5391 17.2666 11.6885 17.1084L13.9912 14.8057L16.2939 17.1084C16.4434 17.2578 16.6367 17.3457 16.874 17.3457C17.3223 17.3457 17.6738 16.9941 17.6738 16.5459C17.6738 16.3262 17.5859 16.1328 17.4365 15.9834L15.125 13.6719L17.4365 11.3516C17.6035 11.1846 17.6826 11.0088 17.6826 10.7979C17.6826 10.3496 17.3311 9.99805 16.8828 9.99805C16.6631 9.99805 16.4785 10.0684 16.3115 10.2354L13.9912 12.5469L11.6709 10.2441C11.5215 10.0859 11.3369 10.0068 11.1084 10.0068C10.6602 10.0068 10.3086 10.3496 10.3086 10.7979C10.3086 11.0176 10.3965 11.2109 10.5547 11.3604L12.8574 13.6719L10.5547 15.9922C10.3965 16.1328 10.3086 16.335 10.3086 16.5459C10.3086 16.9941 10.6602 17.3457 11.1084 17.3457Z"
      fill="#8569C1"
    />
  </svg>
);

export const VideoIcon = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5188 7.5H5.625C5.12772 7.5 4.65081 7.69754 4.29917 8.04918C3.94754 8.40081 3.75 8.87772 3.75 9.375V18.75H14.2688L10.5188 7.5Z"
        fill="#8569C1"
      />
      <path d="M23.6437 18.75L19.8937 7.5H14.4937L18.2249 18.75H23.6437Z" fill="#8569C1" />
      <path d="M34.8938 18.75L31.1438 7.5H23.8501L27.6001 18.75H34.8938Z" fill="#8569C1" />
      <path d="M44.2689 18.75L40.5189 7.5H35.1001L38.8501 18.75H44.2689Z" fill="#8569C1" />
      <path
        d="M54.3749 7.5H44.4937L48.2437 18.75H56.2499V9.375C56.2499 8.87772 56.0524 8.40081 55.7007 8.04918C55.3491 7.69754 54.8722 7.5 54.3749 7.5Z"
        fill="#8569C1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.75 22.5H45.525H56.25V50.625C56.25 51.1223 56.0525 51.5992 55.7008 51.9508C55.3492 52.3025 54.8723 52.5 54.375 52.5H5.625C5.12772 52.5 4.65081 52.3025 4.29917 51.9508C3.94754 51.5992 3.75 51.1223 3.75 50.625V22.5ZM39.75 38.366C40.4167 37.9811 40.4167 37.0189 39.75 36.634L25.875 28.6232C25.2083 28.2383 24.375 28.7195 24.375 29.4893V45.5107C24.375 46.2805 25.2083 46.7617 25.875 46.3768L39.75 38.366Z"
        fill="#8569C1"
      />
    </svg>
  );
};

export const ImageIcon = () => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54.375 15H48.75V9.375C48.75 8.87772 48.5525 8.40081 48.2008 8.04918C47.8492 7.69754 47.3723 7.5 46.875 7.5H5.625C5.12772 7.5 4.65081 7.69754 4.29917 8.04918C3.94754 8.40081 3.75 8.87772 3.75 9.375V43.125C3.75 43.6223 3.94754 44.0992 4.29917 44.4508C4.65081 44.8025 5.12772 45 5.625 45H11.25V50.625C11.25 51.1223 11.4475 51.5992 11.7992 51.9508C12.1508 52.3025 12.6277 52.5 13.125 52.5H54.375C54.8723 52.5 55.3492 52.3025 55.7008 51.9508C56.0525 51.5992 56.25 51.1223 56.25 50.625V16.875C56.25 16.3777 56.0525 15.9008 55.7008 15.5492C55.3492 15.1975 54.8723 15 54.375 15ZM7.5 41.25V11.25H45V15H13.125C12.6277 15 12.1508 15.1975 11.7992 15.5492C11.4475 15.9008 11.25 16.3777 11.25 16.875V41.25H7.5ZM22.5 22.5C23.2417 22.5 23.9667 22.7199 24.5834 23.132C25.2001 23.544 25.6807 24.1297 25.9645 24.8149C26.2484 25.5002 26.3226 26.2542 26.1779 26.9816C26.0333 27.709 25.6761 28.3772 25.1516 28.9016C24.6272 29.4261 23.959 29.7833 23.2316 29.9279C22.5042 30.0726 21.7502 29.9984 21.0649 29.7145C20.3797 29.4307 19.794 28.9501 19.382 28.3334C18.9699 27.7167 18.75 26.9917 18.75 26.25C18.75 25.2554 19.1451 24.3016 19.8484 23.5984C20.5516 22.8951 21.5054 22.5 22.5 22.5ZM48.5437 43.9875C48.3854 44.2932 48.1459 44.5494 47.8515 44.728C47.5572 44.9066 47.2193 45.0007 46.875 45H20.625C20.2787 44.999 19.9396 44.902 19.645 44.72C19.3505 44.538 19.1121 44.278 18.9562 43.9688C18.7983 43.6574 18.7304 43.3082 18.7602 42.9603C18.7901 42.6125 18.9164 42.2799 19.125 42L24.75 34.5C25.0149 34.1489 25.3947 33.902 25.8232 33.8026C26.2516 33.7032 26.7013 33.7576 27.0938 33.9563L29.625 35.2125L36.1688 28.6688C36.3613 28.4759 36.5936 28.3274 36.8494 28.2335C37.1052 28.1396 37.3784 28.1025 37.65 28.125C37.9217 28.1488 38.1849 28.2316 38.4213 28.3676C38.6577 28.5036 38.8616 28.6896 39.0187 28.9125L48.3937 42.0375C48.5958 42.3173 48.7167 42.6473 48.7431 42.9914C48.7696 43.3354 48.7006 43.6801 48.5437 43.9875Z"
      fill="#8569C1"
    />
  </svg>
);

export const PlusIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.54583 12.5625H11.1413V17.1579C11.1413 17.625 11.5255 18.0167 12.0001 18.0167C12.4747 18.0167 12.8589 17.625 12.8589 17.1579V12.5625H17.4543C17.9214 12.5625 18.3131 12.1783 18.3131 11.7037C18.3131 11.2291 17.9214 10.8449 17.4543 10.8449H12.8589V6.24944C12.8589 5.78237 12.4747 5.39062 12.0001 5.39062C11.5255 5.39062 11.1413 5.78237 11.1413 6.24944V10.8449H6.54583C6.07875 10.8449 5.68701 11.2291 5.68701 11.7037C5.68701 12.1783 6.07875 12.5625 6.54583 12.5625Z"
      fill="#8569C1"
    />
  </svg>
);

export const PaperClipIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0624 12.1105L11.4952 17.6777C10.0864 19.094 8.18799 18.9584 6.9751 17.738C5.74714 16.5176 5.61154 14.6342 7.03537 13.2104L14.6442 5.60158C15.4729 4.77289 16.7084 4.62976 17.5144 5.43584C18.3205 6.24946 18.1699 7.47741 17.3412 8.29856L9.87549 15.7793C9.54402 16.1183 9.15228 16.0204 8.9112 15.7944C8.69273 15.5608 8.60233 15.1691 8.9338 14.8301L14.1394 9.62446C14.4031 9.35325 14.4182 8.96151 14.162 8.70537C13.9059 8.4643 13.5142 8.47183 13.2505 8.73551L8.02225 13.9637C7.20864 14.7774 7.23877 16.0204 7.96198 16.7436C8.74547 17.5271 9.92822 17.5045 10.7418 16.6909L18.2452 9.17998C19.7067 7.72602 19.6539 5.80498 18.3733 4.52429C17.1152 3.27373 15.164 3.18333 13.7025 4.64482L6.04848 12.3064C4.14251 14.2199 4.27058 16.9621 6.01081 18.7023C7.74351 20.4275 10.4932 20.5631 12.3992 18.6571L18.0041 13.0597C18.2603 12.8036 18.2603 12.3365 17.9966 12.0954C17.748 11.8242 17.3261 11.8619 17.0624 12.1105Z"
      fill="#8569C1"
    />
  </svg>
);

export const SuccessIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0879 23.1758C19.0713 23.1758 23.1846 19.0625 23.1846 14.0879C23.1846 9.11328 19.0625 5 14.0791 5C9.10449 5 5 9.11328 5 14.0879C5 19.0625 9.11328 23.1758 14.0879 23.1758ZM14.0879 21.3652C10.0537 21.3652 6.82812 18.1221 6.82812 14.0879C6.82812 10.0537 10.0537 6.81934 14.0791 6.81934C18.1133 6.81934 21.3564 10.0537 21.3652 14.0879C21.374 18.1221 18.1221 21.3652 14.0879 21.3652Z"
      fill="#32C977"
    />
    <path
      d="M18 11L13.1875 16L11 13.7273"
      stroke="#32C977"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ErrorIcon = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.9912 22.7422C18.9746 22.7422 23.0879 18.6289 23.0879 13.6543C23.0879 8.67969 18.9658 4.56641 13.9824 4.56641C9.00781 4.56641 4.90332 8.67969 4.90332 13.6543C4.90332 18.6289 9.0166 22.7422 13.9912 22.7422ZM13.9912 20.9316C9.95703 20.9316 6.73145 17.6885 6.73145 13.6543C6.73145 9.62012 9.95703 6.38574 13.9824 6.38574C18.0166 6.38574 21.2598 9.62012 21.2686 13.6543C21.2773 17.6885 18.0254 20.9316 13.9912 20.9316ZM13.9824 15.1133C14.4658 15.1133 14.7471 14.8408 14.7559 14.3311L14.8877 10.1035C14.9053 9.58496 14.5186 9.20703 13.9736 9.20703C13.4287 9.20703 13.0508 9.57617 13.0684 10.0947L13.1914 14.3311C13.209 14.832 13.4902 15.1133 13.9824 15.1133ZM13.9824 18.0312C14.5537 18.0312 15.0195 17.6182 15.0195 17.0557C15.0195 16.502 14.5625 16.0889 13.9824 16.0889C13.4111 16.0889 12.9453 16.502 12.9453 17.0557C12.9453 17.6094 13.4199 18.0312 13.9824 18.0312Z"
      fill="#DA2121"
    />
  </svg>
);

export const XCrossIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 16L16 8M8 8L16 16"
      stroke="#282828"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const CheckboxCheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
      fill="#8569C1"
      stroke="#8569C1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 12L11 14L15 10"
      stroke="#FEFEFE"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const CheckboxUncheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
      stroke="#8569C1"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

import { Link } from "react-router-dom";
import { useTelegram } from "../../hooks/useTelegram";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { getIsAdminQuery, getManagerLink } from "../../services/web-app";
import { FilledButton } from "../primitives/buttons/FilledButton";
import { ContainedButton } from "../primitives/buttons/ContainedButton";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { ListWrapper } from "../primitives/wrappers/ListWrapper";
import { DEFAULT_LOCALE, LOCALES } from "../../common/locales";
import { Rules } from "../Rules/Rules";

export const Home = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRulesAccepted, setIsRulesAccepted] = useState(true);

  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const { tg, sendData } = useTelegram();

  const [link, setLink] = useState("");

  const getIsAdmin = async () => {
    try {
      setIsLoading(true);
      const res = await getIsAdminQuery();
      if (!res.ok) throw new Error("Failed to fetch admin status");
      const data = await res.json();
      setIsAdmin(data.isAdmin);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAnalytics = () => {
    sendData({ type: "get_analytic" });
  };

  const onChangeLanguage = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    changeLanguage(target.value);
    tg.CloudStorage.setItem("language", target.value, () => {});
    sendData({ type: "change_language", language: target.value }, false);
  };

  const getLink = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getManagerLink();
      if (!response.ok) throw new Error("Failed to fetch link");
      const data = await response.json();
      setLink(data.link);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const checkIsRulesAccepted = () => {
    tg.CloudStorage.getItem("isRulesAccepted", (_error: Error, isAccepted: boolean) => {
      setIsRulesAccepted(isAccepted);
    });
  };

  const acceptRulesHandler = () => {
    tg.CloudStorage.setItem("isRulesAccepted", true, () => {});
    setIsRulesAccepted(true);
  };

  useEffect(() => {
    getLink();
    getIsAdmin();
    checkIsRulesAccepted();
    tg.MainButton.hide();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      {!isRulesAccepted && <Rules onBackHandler={acceptRulesHandler} isModal={true} />}
      <section>
        <MainHeadline>{t("home.headline")}</MainHeadline>
        <ListWrapper>
          {!isAdmin && (
            <Link to={"subscription"}>
              <FilledButton>{t("home.buy_subscription")}</FilledButton>
            </Link>
          )}
          {isAdmin && (
            <Link to={"admin-panel"}>
              <FilledButton>{t("home.admin_panel")}</FilledButton>
            </Link>
          )}
          <Link to={"post-form"}>
            <FilledButton>{t("home.public_post")}</FilledButton>
          </Link>
          <Link to={"add-to-group"}>
            <FilledButton>{t("home.adding_to_group")}</FilledButton>
          </Link>
          <Link to={"rules"}>
            <FilledButton>{t("home.rules_of_posting")}</FilledButton>
          </Link>
          {!isAdmin && (
            <Link to={link}>
              <FilledButton disabled={isLoading}>{t("home.ask_manager")}</FilledButton>
            </Link>
          )}
          {isAdmin && (
            <FilledButton onClick={getAnalytics}>{t("home.get_analytics")}</FilledButton>
          )}
        </ListWrapper>
        <div className="flex mb-10">
          <select
            value={
              Object.values(LOCALES).includes(language as LOCALES) ? language : DEFAULT_LOCALE
            }
            onChange={onChangeLanguage}
            className="bg-transparent uppercase cursor-pointer text-primary-100 text-secondary-button"
          >
            {Object.values(LOCALES).map((locale) => (
              <option className="uppercase" key={locale} value={locale}>
                {locale}
              </option>
            ))}
          </select>
        </div>
        <ContainedButton onClick={tg.close}>{t("home.close_app")}</ContainedButton>
      </section>
    </>
  );
};

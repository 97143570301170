interface LgIconButtonProps {
  children: React.ReactNode;
  htmlFor: string;
}

export const LgIconButton = ({ children, htmlFor }: LgIconButtonProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className="bg-light-200 grow text-secondary-button rounded-xl w-full text-center py-6.25 outline-none flex justify-center active:bg-primary-400 hover:bg-primary-400"
    >
      {children}
    </label>
  );
};

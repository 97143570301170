import { useTranslation } from "react-i18next";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { useNavigate } from "react-router-dom";
import { FilledButton } from "../primitives/buttons/FilledButton";
import { useEffect } from "react";
import { useTelegram } from "../../hooks/useTelegram";

interface ForbiddenOverlayProps {
  isOpen: boolean;
}

export const ForbiddenOverlay = ({ isOpen }: ForbiddenOverlayProps) => {
  const { t } = useTranslation();
  const { tg } = useTelegram();
  const navigation = useNavigate();

  useEffect(() => {
    if (isOpen) {
      tg.MainButton.hide();
    }
  }, [isOpen, tg]);

  if (!isOpen) return <></>;

  return (
    <div className="top-0 left-0 fixed z-50 w-screen h-screen bg-light-300 text-center ">
      <section className="px-4 pb-13.5 flex flex-col justify-between h-full">
        <MainHeadline>{t("public_post.headline")}</MainHeadline>
        <div>
          <p className="text-subtitle-1 text-secondary-400">
            {t("public_post.forbidden_message_1")}
          </p>
          <p className="text-subtitle-1 text-secondary-400 mb-7.5">
            {t("public_post.forbidden_message_2")}
          </p>
          <div className="flex flex-col gap-2">
            <FilledButton onClick={() => navigation("/subscription")}>
              {t("public_post.choose_subscription")}
            </FilledButton>
            <FilledButton onClick={() => navigation("/add-to-group")}>
              {t("home.adding_to_group")}
            </FilledButton>
          </div>
        </div>
      </section>
    </div>
  );
};

import { useCallback, useEffect, useState } from "react";
import { bonusesVariantsList } from "../../assets/bonusesVariantsList";
import infoImage from "../../assets/how-to-add-user_XL.jpg";
import { BackButtonProvider } from "../../providers/BackButtonProvider";
import { useTranslation } from "react-i18next";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { SecondaryButton } from "../primitives/buttons/SecondaryButton";
import { Link } from "react-router-dom";
import { getInvitesLinkQuery, getManagerLink } from "../../services/web-app";
import { FilledButton } from "../primitives/buttons/FilledButton";

interface InvitesLinks {
  link: string;
  chatId: string;
  chatName: string;
}

const buttons = [
  {
    text: "Luxury Life ➡️ World",
    link: "http://t.me/+2nhlF8C08_w4ZDAy",
  },
  {
    text: "Luxury Life ➡️ Europe",
    link: "http://t.me/+b2gEBjopQ8Y2MDYy",
  },
  {
    text: "Luxury Life ➡️ Topics",
    link: "http://t.me/+2stSBpgTBjIzZjdi",
  },
  {
    text: "Luxury Life ➡️ Tours",
    link: "http://t.me/+6pj_df5lOwxhMzli",
  },
  {
    text: "Secret Models 🔞 Party",
    link: "http://t.me/+khWl2KBPon85YWJi",
  },
  {
    text: "Secret Models 🔞 Work",
    link: "http://t.me/+ybMJDqXZN0k1MzMy",
  },
  {
    text: "Secret Models 🔞 Tours",
    link: "http://t.me/+Tz-kD4DbZ_FhOTRi",
  },
  {
    text: "Secret Models 🔞 Themes",
    link: "http://t.me/+8YzIdTh2tUczMjhi",
  },
];

export const AddToGroupInfo = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState("");
  const [invitesLinks, setInvitesLinks] = useState<InvitesLinks[]>([]);
  const [invitesLinksLoading, setInvitesLinksLoading] = useState(false);

  const getLink = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await getManagerLink();
      if (!response.ok) throw new Error("Failed to fetch link");
      const data = await response.json();
      setLink(data.link);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const generateInviteLink = async () => {
    try {
      setInvitesLinksLoading(true);
      const response = await getInvitesLinkQuery();
      if (!response.ok) throw new Error("Failed to fetch link");
      const data = await response.json();
      setInvitesLinks(data);
    } catch (error) {
      console.log(error);
    } finally {
      setInvitesLinksLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert("Link copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy:", error);
      alert("Failed to copy link to clipboard. Please try manually copying the link.");
    }
  };

  useEffect(() => {
    getLink();
  }, []);

  return (
    <BackButtonProvider>
      <section className="add-to-group-section">
        <MainHeadline>{t("adding_to_group.headline")}</MainHeadline>
        <p className="text-body-1 text-secondary-300 text-left mb-3">
          {t("adding_to_group.caption_3")}
        </p>
        <ul className="mb-3 flex flex-col gap-2">
          {invitesLinks.map((item) => (
            <li className="flex justify-between" key={item.chatId}>
              <div className="flex flex-col justify-start">
                <p className="text-h4 text-left">{item.chatName}</p>
                <button
                  type="button"
                  onClick={() => copyToClipboard(item.link)}
                  className="text-primary-100 text-body-1 text-left cursor-pointer"
                >
                  {item.link}
                </button>
              </div>
              <button
                type="button"
                className="text-primary-100 text-body-1 cursor-pointer bg-light-200 rounded-lg text-center py-0.5 px-3"
                onClick={() => copyToClipboard(item.link)}
              >
                {t("common.copy")}
              </button>
            </li>
          ))}
        </ul>

        {
          <div className="mb-3.5 mt-1">
            <FilledButton disabled={invitesLinksLoading} onClick={generateInviteLink}>
              {t(
                invitesLinksLoading
                  ? "common.loading"
                  : invitesLinks.length
                  ? "adding_to_group.regenerate_invite_links"
                  : "adding_to_group.generate_invite_links"
              )}
            </FilledButton>
          </div>
        }

        <p className="text-body-1 text-secondary-300 mb-3 text-left">
          {t("adding_to_group.intro")}
        </p>
        <div className="gap-2 flex flex-col mb-10">
          {buttons.map(({ text, link }) => (
            <Link to={link}>
              <FilledButton>{text}</FilledButton>
            </Link>
          ))}
        </div>
        <p className="text-body-1 text-secondary-300 mb-3 text-left">
          {t("adding_to_group.caption_1")}
        </p>
        <img className="mb-10" src={infoImage} alt="Click on plus to group button" />

        <p className="text-body-1 text-secondary-300 text-left mb-5">
          {t("adding_to_group.caption_2")}
        </p>

        <ul className="mb-10 flex flex-col gap-5">
          {bonusesVariantsList.map((item) => (
            <li key={item.id}>
              <h3 className="text-h3 text-left mb-0.5">
                {t("adding_to_group.for_count_members", {
                  count: item.membersCount,
                })}
              </h3>
              <div className="flex gap-1">
                <p>
                  {t("adding_to_group.month_of_subscription", {
                    count: item.duration,
                  })}
                </p>
                <span className="uppercase text-primary-100 text-secondary-button">
                  {t("common.free")}
                </span>
              </div>
            </li>
          ))}
        </ul>

        <SecondaryButton classes={"mb-3.5"} disabled={isLoading}>
          <Link to={link}>{t("home.ask_manager")}</Link>
        </SecondaryButton>
        <p className="text-body-1 text-secondary-300 mb-3 flex flex-col">
          <span>{t("adding_to_group.ending_1")}</span> <span>{t("adding_to_group.ending_2")}</span>
        </p>
      </section>
    </BackButtonProvider>
  );
};

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FilledButton } from "../primitives/buttons/FilledButton";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { getIsSuccessfulPaymentQuery } from "../../services/web-app";

export const SuccessfulPayment = () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState(false);
  const NP_id = new URLSearchParams(search).get("NP_id") || "";
  const botUsername = import.meta.env.VITE_BOT_USERNAME;

  const fetchPayment = async () => {
    try {
      if (!NP_id) {
        throw new Error("Id is not provided");
      }
      setIsLoading(true);
      const response = await getIsSuccessfulPaymentQuery(NP_id);
      if (!response.ok) {
        throw new Error("Failed to fetch payment data");
      }

      const { isFinished } = await response.json();
      if (!isFinished) {
        throw new Error("Payment is not finished. Please try again in 1-2 minutes.");
      }
      setIsLoading(false);
    } catch (error) {
      setError(error instanceof Error ? error.message : "Failed to fetch payment data");
      setIsLoading(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  if (error) {
    return <p className="text-lg text-red-500">{error}</p>;
  }

  return (
    <section className={"add-to-group-section"}>
      {isLoading ? (
        <p>{t("common.loading")}</p>
      ) : (
        <>
          <MainHeadline>{t("successful_payment.headline")}</MainHeadline>

          <div className="flex flex-row items-center gap-2">
            <p className="text-h4 text-dark-100 text-left">
              {t("successful_payment.payment_id")}:
            </p>
            <p className="text-h4 text-dark-100 text-left ">{NP_id}</p>
            <button
              className="text-body-1 text-secondary-300 text-left underline cursor-pointer"
              onClick={() => copyToClipboard(NP_id)}
            >
              {copySuccess ? t("successful_payment.copied") : t("successful_payment.copy")}
            </button>
          </div>

          <div className="flex flex-col gap-5 mt-5 mb-10">
            <p className="text-body-1 text-secondary-300 text-left">
              {t("successful_payment.message_1")}
            </p>
            <p className="text-body-1 text-secondary-300 text-left">
              {t("successful_payment.message_2")}
            </p>
          </div>

          <a
            rel="noopener noreferrer nofollow"
            target="_blank"
            href={`https://t.me/${botUsername}?start=${NP_id}`}
          >
            <FilledButton>{t("successful_payment.accept_button")}</FilledButton>
          </a>
        </>
      )}
    </section>
  );
};

import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../hooks/useTelegram";
import { ChangeEvent, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BackButtonProvider } from "../../providers/BackButtonProvider";
import { IContent, Itemplate } from "../../types/post";
import {
  getCanMessageQuery,
  getPremiumPostQuery,
  getStopWordsQuery,
  nextPostTimeQuery,
} from "../../services/web-app";
import { useTranslation } from "react-i18next";
import { SecondaryButton } from "../primitives/buttons/SecondaryButton";
import { ListWrapper } from "../primitives/wrappers/ListWrapper";
import { ContainedButton } from "../primitives/buttons/ContainedButton";
import { CrossIcon, TrashIcon } from "../../assets/icons/icons";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { FilledButton } from "../primitives/buttons/FilledButton";
import { useNotificationContext } from "../../hooks/useNotificationContext";
import { ForbiddenOverlay } from "./ForbiddenOverlay";
import { BannedOverlay } from "./BannedOverlay";
import { uploadFile } from "../../utils/uploadFile";
import { AcceptPhoto } from "./AcceptPhoto";
import { AcceptVideo } from "./AcceptVideo";
import classNames from "classnames";

const MAX_POST_LENGHT = 1000;
const REFETCH_NEXT_TIME_PERIOD = 5000;

export interface IButton {
  link: string;
  text: string;
}

export function PostForm() {
  const intervalRef = useRef<number | null>(null);
  const nextTimerRefetchInterval = useRef<number | null>(null);
  const nextTimeLoading = useRef<boolean>(false);

  const navigation = useNavigate();
  const { setSuccess, setError: setErrorNotification } = useNotificationContext();
  const { tg, sendData } = useTelegram();
  const { t } = useTranslation();

  const [lastTime, setLastTime] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [stopWords, setStopWords] = useState<string[]>([]);
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [videoFile, setVideoFile] = useState<File | undefined>(undefined);
  const [content, setContent] = useState<IContent[]>([]);
  const [buttons, setButtons] = useState<IButton[]>([]);
  const [postTemplates, setPostTemplates] = useState<Itemplate[]>([]);
  const [templateName, setTemplateName] = useState("");
  const [isOpenTemaplateSave, setIsOpenTemaplateSave] = useState(false);
  const [canPost, setCanPost] = useState<boolean>(false);
  const [isBanned, setIsBanned] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isPremiumSubscriber, setIsPremiumSubscriber] = useState<boolean>(false);
  const [nextPostTime, setNextPostTime] = useState<string | null>(null);
  const [isInitialLoading, setInitialLoading] = useState(true);
  const [totalLength, setTotalLength] = useState(0);
  const [premiumPost, setPremiumPost] = useState<null | {
    image: string;
    text: string;
    video: string;
    buttons: IButton[];
  }>(null);

  const getPremiumPost = useCallback(async () => {
    try {
      if (!tg.initDataUnsafe?.user?.id) return;
      setIsLoading(true);
      setError("");
      const response = await getPremiumPostQuery(tg.initDataUnsafe.user.id);
      if (!response.ok) throw new Error("Failed to fetch premium post");
      const data = await response.json();
      setPremiumPost(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [tg]);

  const getPost = useCallback(() => {
    let text = "";

    content.forEach((item) => {
      if (item.type === "divider") {
        text += `\n${item.content}\n`;
      }
      if (item.type === "text") {
        if (content.length > 1) {
          text += `\n${item.content}\n`;
        } else {
          text += `${item.content}`;
        }
      }
      if (item.type === "list") {
        if (item?.items?.length) {
          text += "\n";
          text +=
            item.items
              .map((listItem) => {
                return `${item.firstChars ? `${item.firstChars}` : ""}${listItem}`;
              })
              .join("\n") + "\n";
        }
      }
    });
    const post = {
      type: "post",
      text,
      buttons,
      image,
      video,
    };
    return post;
  }, [image, video, content, buttons]);

  const stopSendingPost = () => {
    sendData({
      type: "stop_sending_post",
    });
  };

  const refetchNextTimePost = useCallback(() => {
    nextTimerRefetchInterval.current = setInterval(() => {
      getNextTimePost();
    }, REFETCH_NEXT_TIME_PERIOD);
  }, []);

  const startCountDown = () => {
    intervalRef.current = setInterval(() => {
      const now = new Date();
      const nextTime = new Date(nextPostTime || "");

      const distance = nextTime.getTime() - now.getTime();

      setLastTime(distance);

      if (distance <= 1000) {
        setNextPostTime(null);
        !nextTimerRefetchInterval.current && refetchNextTimePost();
        intervalRef.current && clearInterval(intervalRef.current as number);
        return;
      } else {
        nextTimerRefetchInterval.current &&
          clearInterval(nextTimerRefetchInterval.current as number);
      }
    }, 1000);
  };

  useEffect(() => {
    if (!nextPostTime) return;
    intervalRef.current && clearInterval(intervalRef.current as number);
    startCountDown();

    return () => {
      intervalRef.current && clearInterval(intervalRef.current as number);
      nextTimerRefetchInterval.current &&
        clearInterval(nextTimerRefetchInterval.current as number);
    };
  }, [nextPostTime]);

  const getNextTimePost = useCallback(async () => {
    try {
      if (!tg.initDataUnsafe?.user?.id || nextTimeLoading.current) return;
      nextTimeLoading.current = true;
      const response = await nextPostTimeQuery();
      if (!response.ok) throw new Error("Failed to next posting time");
      const data = await response.json();
      setNextPostTime(data?.nextTime);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      nextTimeLoading.current = false;
    }
  }, []);

  const replaceAll = (str: string, find: string, replace: string) => {
    return str.replace(new RegExp(find, "g"), replace);
  };

  const containsStopWords = (text: string, stopWords: string[]) => {
    const lowercasedText = text.toLowerCase();
    const lowercasedStopWords = stopWords.map((word) => word.toLowerCase());
    const textList = replaceAll(lowercasedText, "\n", "").split(" ");

    const hasStopWords = lowercasedStopWords.some((stopWord) => {
      const includesWord = textList.includes(stopWord);

      if (includesWord) {
        setError(
          t("public_post.word_not_allowed", {
            word: stopWord,
          })
        );
      }

      return includesWord;
    });

    if (hasStopWords) {
      setErrorNotification(t("notifications.forbidden_words"));
    }

    return hasStopWords;
  };

  const isValidContent = () => {
    if (!content.length || !stopWords.length) return false;

    const post = getPost().text;

    setTotalLength(post.length);

    if (!post.replace(/\n/g, "")?.trim()?.length) return false;

    if (post.length > MAX_POST_LENGHT) {
      setErrorNotification(
        t("notifications.too_large_post", {
          maxPostLenght: MAX_POST_LENGHT,
        })
      );
      return false;
    }

    return !containsStopWords(post, stopWords);
  };

  const getTemplates = useCallback(async () => {
    try {
      setIsLoading(true);
      tg.CloudStorage.getKeys((error: Error, keys: string[]) => {
        if (error) throw new Error("Failed to fetch templates");
        const filteredKeys = keys.filter((key) => key.startsWith("template"));
        tg.CloudStorage.getItems(filteredKeys, (error: Error, data: Record<string, string>) => {
          if (error) throw new Error("Failed to fetch templates");
          const templates = Object.values(data).map((item) => JSON.parse(item));
          setPostTemplates(templates);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [tg]);

  const getCanMessage = useCallback(async () => {
    try {
      if (!tg.initDataUnsafe?.user?.id) return;
      setIsLoading(true);
      const response = await getCanMessageQuery(tg.initDataUnsafe.user.id);
      if (!response.ok) throw new Error("Failed to fetch can message");
      const data = await response.json();
      setCanPost(data?.canPost);
      setIsBanned(data?.isBanned);
      setIsPremiumSubscriber(data?.isPremiumSubscriber);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [tg]);

  const getStopWords = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getStopWordsQuery();
      if (!response.ok) throw new Error("Failed to fetch stop words");
      const data = await response.json();
      setStopWords(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const saveTemplate = useCallback(async () => {
    try {
      if (!templateName.trim() || !stopWords.length) return;
      setIsLoading(true);
      const template = {
        name: templateName,
        items: content,
        image,
        video,
        buttons,
      };
      tg.CloudStorage.setItem(`template_${Date.now()}`, JSON.stringify(template), () => {
        setPostTemplates((prev) => [...prev, template]);
        setTemplateName("");
        setIsOpenTemaplateSave(false);
        setSuccess(t("notifications.success_action_1"));
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [content, templateName]);

  const handleAddText = () => {
    setContent((prev) => [...prev, { type: "text", content: "" }]);
  };

  const handleInputChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const updatedContent = [...content];
    updatedContent[index].content = event.target.value;
    setContent(updatedContent);
  };

  const handleListItemChange = (
    parentIndex: number,
    childIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedContent = [...content];
    // @ts-ignore
    updatedContent[parentIndex].items[childIndex] = event.target.value;
    setContent(updatedContent);
  };

  const handleFirstCharsChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const updatedContent = [...content];
    updatedContent[index].firstChars = event.target.value;
    setContent(updatedContent);
  };

  const handleAdjustTemplate = (templateIndex: number) => {
    setContent([...postTemplates[templateIndex].items]);
    setImage(postTemplates[templateIndex].image);
    setVideo(postTemplates[templateIndex].video);
    setButtons(postTemplates[templateIndex].buttons || []);
  };

  const removeMedia = useCallback(() => {
    setImage("");
    setVideo("");
    setImageFile(undefined);
    setVideoFile(undefined);
  }, []);

  const sendPost = useCallback(async () => {
    const post = getPost();
    try {
      sendData(post);
    } catch (error) {
      console.log(error);
    }
  }, [getPost, sendData]);

  const addButton = useCallback(() => {
    setButtons((prev) => [...prev, { link: "", text: "" }]);
  }, []);

  const removeButton = useCallback((index: number) => {
    setButtons((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleTextButtonChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const updatedButtons = [...buttons];
    updatedButtons[index].text = event.target.value;
    setButtons(updatedButtons);
  };

  const handleLinkButtonChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const updatedButtons = [...buttons];
    updatedButtons[index].link = event.target.value;
    setButtons(updatedButtons);
  };

  const isUrlValid = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const initialRequest = async () => {
    setInitialLoading(true);
    await getTemplates();
    await getStopWords();
    await getPremiumPost();
    await getCanMessage();
    await getNextTimePost();
    setInitialLoading(false);
  };

  const isButtonsInvalid = useMemo(
    () =>
      buttons.some(
        (button) => !button.text.trim() || !button.link.trim() || !isUrlValid(button.link)
      ),
    [buttons]
  );

  useEffect(() => {
    if (!premiumPost) return;
    setImage(premiumPost.image);
    setVideo(premiumPost.video);
    setButtons(premiumPost.buttons);
    setContent([{ type: "text", content: premiumPost.text }]);
  }, [premiumPost]);

  useEffect(() => {
    initialRequest();
  }, []);

  useEffect(() => {
    if (!imageFile) return;
    uploadFile(imageFile, setImage, setIsLoading);
  }, [imageFile, uploadFile]);

  useEffect(() => {
    if (!videoFile) return;
    uploadFile(videoFile, setVideo, setIsLoading);
  }, [videoFile, uploadFile]);

  useEffect(() => {
    if (
      !isValidContent() ||
      isLoading ||
      !canPost ||
      (!isPremiumSubscriber && nextPostTime) ||
      isButtonsInvalid
    ) {
      tg.MainButton.hide();
    } else {
      setError("");
      tg.MainButton.show();
      tg.MainButton.setText(
        t(isPremiumSubscriber ? "public_post.autopost_post" : "public_post.send_post")
      );
    }
  }, [content, tg, isLoading, stopWords, canPost, nextPostTime, isButtonsInvalid]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", sendPost);
    return () => {
      tg.offEvent("mainButtonClicked", sendPost);
    };
  }, [sendPost, tg]);

  const getLastTime = (time: number) => {
    if (!time) return null;

    if (time < 1000) return "0d 00h ~01m 00s";

    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return `${days ? `0${days}m`.slice(-3) : "0d"} ${hours ? `00${hours}h`.slice(-3) : "00h"} ${
      minutes ? `00${minutes}m`.slice(-3) : "00m"
    } ${seconds ? `00${seconds}s`.slice(-3) : "00s"}`;
  };

  const lastTimeString = getLastTime(lastTime);

  if (isInitialLoading) return <p>{t("common.loading")}</p>;

  return (
    <BackButtonProvider>
      <ForbiddenOverlay isOpen={!canPost && !isBanned} />
      <BannedOverlay isOpen={isBanned} />
      <section className="post-section">
        <MainHeadline>{t("public_post.headline")}</MainHeadline>
        <ListWrapper>
          {!isOpenTemaplateSave && (
            <select
              className="text-primary-100 bg-transparent border-primary-100 rounded-lg border px-3 py-2.5 cursor-pointer outline-none"
              onChange={(e) => handleAdjustTemplate(+e.target.value)}
            >
              <option value="">{t("public_post.select_template")}</option>
              {postTemplates.map((template, index) => (
                <option key={index} value={index}>
                  {template.name}
                </option>
              ))}
            </select>
          )}
          {isLoading ? (
            <p>{t("common.loading")}</p>
          ) : (
            <div
              className={classNames(
                "flex relative rounded-10 overflow-hidden",
                video || image ? "bg-light-200" : "gap-5"
              )}
            >
              {(video || image || "") && (
                <button
                  onClick={removeMedia}
                  type="button"
                  className="absolute bg-light-200 rounded-lg p-0 top-2 right-2 z-10"
                >
                  <CrossIcon />
                </button>
              )}
              <AcceptPhoto image={image} video={video} setImageFile={setImageFile} />
              <AcceptVideo image={image} video={video} setVideoFile={setVideoFile} />
            </div>
          )}

          <p
            className={classNames(
              "font-semibold text-right mb-2",
              totalLength > MAX_POST_LENGHT ? "text-red-500" : "text-black"
            )}
          >
            {totalLength}/{MAX_POST_LENGHT}
          </p>

          {content.map((item, index) => (
            <Fragment key={index}>
              {item.type === "divider" && (
                <input
                  className="text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
                  type="text"
                  value={item.content}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder={t("public_post.divider_placeholder")}
                />
              )}
              {item.type === "text" && (
                <textarea
                  rows={4}
                  className="text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
                  value={item.content}
                  onChange={(e) => handleInputChange(index, e)}
                  placeholder={t("public_post.text_placeholder")}
                />
              )}
              {item.type === "list" && (
                <ListWrapper classes="mb-0">
                  {item.items &&
                    item.items.map((listItem, itemIndex) => (
                      <input
                        className="text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
                        type="text"
                        key={itemIndex}
                        value={listItem}
                        onChange={(e) => handleListItemChange(index, itemIndex, e)}
                        placeholder={t("public_post.list_placeholder")}
                        max={1}
                      />
                    ))}
                  <div className="flex gap-3.5 items-center">
                    <div className="grow flex items-center gap-4">
                      <p className="text-primary-100 text-subtitle-1">
                        {t("public_post.list_emoji")}
                      </p>
                      <input
                        className="w-12 text-dark-100 bg-light-200 rounded-xl border px-3 py-2.5 cursor-pointer outline-none"
                        type="text"
                        value={item.firstChars}
                        onChange={(e) => handleFirstCharsChange(index, e)}
                        placeholder="( -:"
                      />
                    </div>
                    <SecondaryButton
                      classes={"w-half"}
                      onClick={() => {
                        const updatedContent = [...content];
                        // @ts-ignore
                        updatedContent[index].items.push("");
                        setContent(updatedContent);
                      }}
                    >
                      {t("public_post.add_item")}
                    </SecondaryButton>
                  </div>
                </ListWrapper>
              )}
              <hr />
            </Fragment>
          ))}

          <SecondaryButton onClick={handleAddText}>{t("public_post.add_text")}</SecondaryButton>

          {buttons.map((button, index) => (
            <ListWrapper key={index} classes="mb-0">
              <div className="flex justify-between">
                <p className="text-h4 text-dark-100">
                  {t("public_post.button_text", {
                    count: index + 1,
                  })}
                </p>
                <button type="button" onClick={() => removeButton(index)}>
                  <TrashIcon />
                </button>
              </div>
              <input
                className="text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
                type="text"
                value={button.text}
                onChange={(e) => handleTextButtonChange(index, e)}
                placeholder={t("public_post.button_placeholder")}
              />
              <input
                className="text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
                type="text"
                value={button.link}
                onChange={(e) => handleLinkButtonChange(index, e)}
                placeholder={t("public_post.button_link_placeholder")}
              />
              <hr />
            </ListWrapper>
          ))}
          <SecondaryButton onClick={addButton}>{t("public_post.add_button")}</SecondaryButton>

          {((content.length && !error) || "") && (
            <>
              {isOpenTemaplateSave ? (
                <ListWrapper>
                  <input
                    className="text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
                    disabled={isLoading}
                    type="text"
                    placeholder={t("public_post.template_name_placeholder")}
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                  />
                  {!isLoading && (
                    <div className="flex gap-3.5">
                      <FilledButton onClick={saveTemplate}>{t("common.save")}</FilledButton>
                      <FilledButton onClick={() => setIsOpenTemaplateSave(false)}>
                        {t("common.cancel")}
                      </FilledButton>
                    </div>
                  )}
                </ListWrapper>
              ) : (
                <SecondaryButton onClick={() => setIsOpenTemaplateSave(true)}>
                  {t("public_post.save_template")}
                </SecondaryButton>
              )}
            </>
          )}
          {premiumPost && (
            <SecondaryButton onClick={stopSendingPost}>
              {t("public_post.stop_sending_posts")}
            </SecondaryButton>
          )}
          {error && <p className="text-red-500">{error}</p>}
        </ListWrapper>
        {(!isPremiumSubscriber || premiumPost) &&
          lastTimeString &&
          (isPremiumSubscriber || nextPostTime) && (
            <>
              <h2 className="text-dark-200 text-h4 text-left mb-2">
                {t(
                  isPremiumSubscriber
                    ? "public_post.autopost_again_in"
                    : "public_post.can_post_again_in"
                )}
              </h2>
              <p className="text-dark-200 font-semibold text-h3 text-left mb-4">
                {lastTimeString}
              </p>
            </>
          )}
        <ContainedButton onClick={() => navigation(-1)}>{t("common.cancel")}</ContainedButton>
      </section>
    </BackButtonProvider>
  );
}

import { ButtonProps } from "../../../types/button-props";

interface FilledButtonProps extends ButtonProps {
  children: React.ReactNode;
}

export const FilledButton = ({ children, ...props }: FilledButtonProps) => {
  return (
    <button
      type="button"
      className="bg-primary-100 bg-gradient-to-b from-primary-100 to-primary-300 text-white text-button rounded-lg w-full text-center py-3.5 outline-none hover:from-[#5e4b87] hover:to-[#7d6f9a] active:from-[#5e4b87] active:to-[#7d6f9a]"
      {...props}
    >
      {children}
    </button>
  );
};

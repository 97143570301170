import classNames from "classnames";

interface ListWrapperProps {
  children: React.ReactNode;
  classes?: string;
}

export const ListWrapper = ({ classes, children }: ListWrapperProps) => {
  return <div className={classNames("flex flex-col gap-3.5", classes ?? "mb-6")}>{children}</div>;
};

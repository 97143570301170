import { BackButtonProvider } from "../../providers/BackButtonProvider";
import { useTranslation } from "react-i18next";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { FilledButton } from "../primitives/buttons/FilledButton";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

interface RulesProps {
  onBackHandler?: () => void;
  isModal?: boolean;
}

export const Rules = ({ onBackHandler, isModal = false }: RulesProps) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const goBack = () => {
    navigation(-1);
  };

  return (
    <BackButtonProvider>
      <section
        className={classNames(
          isModal
            ? "fixed top-0 left-half -translate-x-half w-screen h-screen bg-white "
            : "add-to-group-section"
        )}
      >
        {isModal ? (
          <h2 className="text-h1 mt-6 mb-4 text-dark-100">{t("rules.headline")}</h2>
        ) : (
          <MainHeadline>{t("rules.headline")}</MainHeadline>
        )}

        <p className="text-h4 text-dark-100 text-left mb-5">{t("rules.intro")}</p>

        <div className="flex flex-col gap-5 mb-10">
          <p className="text-body-1 text-secondary-300 text-left">{t("rules.rule_1")}</p>
          <p className="text-body-1 text-secondary-300 text-left">{t("rules.rule_2")}</p>
          <p className="text-body-1 text-secondary-300 text-left">{t("rules.rule_3")}</p>
          <p className="text-body-1 text-secondary-300 text-left">{t("rules.rule_4")}</p>
        </div>

        <FilledButton onClick={onBackHandler || goBack}>{t("rules.accept_button")}</FilledButton>
      </section>
    </BackButtonProvider>
  );
};

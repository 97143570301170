const tg = window.Telegram.WebApp;

const BASE_URL = import.meta.env.VITE_BOT_API_URL;

export const postQueryBuilder = (path: string, data: Record<string, any>) => {
  return fetch(BASE_URL + path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data,
      initData: tg.initData,
      userId: tg.initDataUnsafe?.user?.id,
      queryId: tg.initDataUnsafe?.query_id,
    }),
  });
};

export const queryWebApp = (data: Record<string, string | number | string[]>) => {
  return postQueryBuilder("web-app", data);
};

export const getManagerLink = () => {
  return fetch(BASE_URL + "manager-link", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getStopWordsQuery = () => {
  return fetch(BASE_URL + "stop-words", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getFilterPostsQuery = (
  searchString: string
  // lowerDate?: Date,
  // greaterDate?: Date
) => {
  return fetch(`${BASE_URL}posts?searchString=${searchString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getFilterUsersQuery = (searchString: string, isBanned?: boolean) => {
  return fetch(`${BASE_URL}users?isBanned=${isBanned}&searchString=${searchString}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUsersQuery = () => {
  return fetch(BASE_URL + "users/shorted", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getManagersQuery = () => {
  return fetch(BASE_URL + "managers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteManagerQuery = (id: string) => {
  return fetch(BASE_URL + "managers/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const addManagerQuery = (userName: string) => {
  return fetch(BASE_URL + "managers", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userName }),
  });
};

export const getPremiumPostQuery = (id: number) => {
  return fetch(BASE_URL + "premium-post/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getCanMessageQuery = (id: number) => {
  return fetch(BASE_URL + "users/can-post/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getUserPremiumChatsQuery = (id: number) => {
  return fetch(BASE_URL + "users/premium-chats/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPremiumChatsQuery = () => {
  return fetch(BASE_URL + "users/premium-chats", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getMySubscriptionsQuery = () => {
  return fetch(BASE_URL + "users/subscriptions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: tg.initData,
      userId: tg.initDataUnsafe?.user?.id,
      queryId: tg.initDataUnsafe?.query_id,
    }),
  });
};

export const getIsAdminQuery = () => {
  return fetch(BASE_URL + "users/is-admin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: tg.initData,
      userId: tg.initDataUnsafe?.user?.id,
      queryId: tg.initDataUnsafe?.query_id,
    }),
  });
};

export const updateTimezone = async () => {
  try {
    const res = await fetch(BASE_URL + "users/timezone", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        initData: tg.initData,
        userId: tg.initDataUnsafe?.user?.id,
        queryId: tg.initDataUnsafe?.query_id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    }).then((res) => res.json());

    if (res?.message) {
      console.log(res.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const nextPostTimeQuery = () => {
  return fetch(BASE_URL + "users/next-post-time", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: tg.initData,
      userId: tg.initDataUnsafe?.user?.id,
      queryId: tg.initDataUnsafe?.query_id,
    }),
  });
};

export const getSubscriptionsListQuery = () => {
  return fetch(BASE_URL + "subscriptions", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getInvoiceLinkQuery = (id: string, groups: string[]) => {
  return fetch(BASE_URL + "subscriptions/invoice", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: tg.initData,
      userId: tg.initDataUnsafe?.user?.id,
      queryId: tg.initDataUnsafe?.query_id,
      subscriptionId: id,
      groups,
    }),
  });
};

export const getIsSuccessfulPaymentQuery = (id: string) => {
  return fetch(BASE_URL + `invoices/success/${id}`);
};

export const getInvitesLinkQuery = () => {
  return fetch(BASE_URL + "invites-link", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: tg.initData,
      userId: tg.initDataUnsafe?.user?.id,
      queryId: tg.initDataUnsafe?.query_id,
    }),
  });
};

export const getInvitedMembersQuery = () => {
  return fetch(BASE_URL + "invited-members", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      initData: tg.initData,
      userId: tg.initDataUnsafe?.user?.id,
      queryId: tg.initDataUnsafe?.query_id,
    }),
  });
};

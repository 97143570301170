import { memo } from "react";
import { LgIconButton } from "../primitives/buttons/LgIconButton";
import { VideoIcon } from "../../assets/icons/icons";

interface AcceptVideoProps {
  image: string;
  video: string;
  setVideoFile: (file: File | undefined) => void;
}

export const AcceptVideo = memo(
  ({ image, video, setVideoFile }: AcceptVideoProps) => {
    console.log("VideoComponent", image, video);
    if (image) return null;
    return (
      <>
        {video ? (
          <video className="object-cover w-full" src={video} controls playsInline muted autoPlay />
        ) : (
          <LgIconButton htmlFor="postVideo">
            <VideoIcon />
            <input
              style={{ display: "none" }}
              id="postVideo"
              type="file"
              multiple={false}
              onChange={({ target }) => {
                console.log('selected files', target, target.files);
                setVideoFile(target.files?.[0] || undefined);
              }}
              accept="video/*"
            />
          </LgIconButton>
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.image === nextProps.image && prevProps.video === nextProps.video
);

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTelegram } from "../../hooks/useTelegram";
import emojiIcon from "../../assets/icons/hand_emoji.png";
import { BackButtonProvider } from "../../providers/BackButtonProvider";
import { useTranslation } from "react-i18next";
import { MainHeadline } from "../primitives/headlines/MainHeadline";
import { PaymentPart } from "./PaymentPart";
import { ListWrapper } from "../primitives/wrappers/ListWrapper";
import {
  getInvitedMembersQuery,
  getInvoiceLinkQuery,
  getMySubscriptionsQuery,
  getPremiumChatsQuery,
  getSubscriptionsListQuery,
} from "../../services/web-app";
import { formatDate } from "../../utils/formatDate";

interface InvitedToChatStatistic {
  chat: string;
  usersCount: number;
}
interface IChat {
  name: string;
  id: string;
}

interface IMySubscription {
  id: string;
  expireAt: number;
}

interface ISubscription {
  _id: string;
  price: number;
  name: string;
  caption: string;
  subcaption: string;
  isMostPopular: boolean;
}

export const SubscriptionList = () => {
  const { t } = useTranslation();
  const { tg } = useTelegram();
  const [subscriptionList, setSubscriptionList] = useState<ISubscription[]>([]);
  const [activeSubscription, setActiveSubscription] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [premiumChats, setPremiumChats] = useState<IChat[]>([]);
  const [userPremiumChats, setUserPremiumChats] = useState<string[]>([]);
  const [mySubscriptions, setMySubscriptions] = useState<IMySubscription[]>([]);
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [isPaymentLinkLoading, setIsPaymentLinkLoading] = useState(false);
  const [invitedMembers, setInvitedMembers] = useState<InvitedToChatStatistic[]>([]);
  const [myManualMessagingChats, setMyManualMessagingChats] = useState<IMySubscription[]>([]);

  const getPaymentLink = useCallback(async () => {
    try {
      if (!activeSubscription || !userPremiumChats.length) return;
      setIsPaymentLinkLoading(true);
      setError("");
      setPaymentLink("");

      const response = await getInvoiceLinkQuery(activeSubscription as string, userPremiumChats);
      if (!response.ok) throw new Error("Failed to fetch payment link");
      const data = await response.json();
      setPaymentLink(data.invoice_url);
    } catch (error) {
      console.log(error);
      error instanceof Error && setError(error.message);
    } finally {
      setIsPaymentLinkLoading(false);
    }
  }, [tg, userPremiumChats, activeSubscription]);

  const price = useMemo(
    () => subscriptionList.find((item) => item._id === activeSubscription)?.price || 0,
    [activeSubscription, userPremiumChats]
  );

  const getPremiumChats = useCallback(async () => {
    if (!tg?.initDataUnsafe?.user?.id) return;

    const chatsResponse = await getPremiumChatsQuery();
    if (!chatsResponse.ok) throw new Error("Failed to fetch premium chats");
    const chatsData = await chatsResponse.json();

    setPremiumChats(chatsData);
    setUserPremiumChats(chatsData.map((item: IChat) => item.id));
  }, []);

  const getMySubscriptions = useCallback(async () => {
    if (!tg?.initDataUnsafe?.user?.id) return;
    const chatsResponse = await getMySubscriptionsQuery();
    if (!chatsResponse.ok) throw new Error("Failed to fetch my subscriptions");
    const chatsData = await chatsResponse.json();

    setMySubscriptions(chatsData.subscriptions);
    setMyManualMessagingChats(
      chatsData.manualMessaging.filter((item: IMySubscription) => item.expireAt > Date.now())
    );
  }, []);

  const getInvitedMembers = useCallback(async () => {
    const response = await getInvitedMembersQuery();
    if (!response.ok) throw new Error("Failed to fetch invited members");
    const data = await response.json();
    setInvitedMembers(data);
  }, []);

  const getSubscriptionsList = useCallback(async () => {
    const response = await getSubscriptionsListQuery();
    if (!response.ok) throw new Error("Failed to fetch subscriptions");
    const data:ISubscription[] = await response.json();
    setSubscriptionList(data?.sort((a, b) => b.price - a.price));
  }, []);

  const goToPaymentLink = () => {
    if (!paymentLink) return;
    window.open(paymentLink, "_blank");
    setTimeout(() => tg.close(), 500);
  };

  useEffect(() => {
    setPaymentLink("");
  }, [activeSubscription, userPremiumChats]);

  useEffect(() => {
    if (paymentLink) {
      tg.MainButton.show();
      tg.MainButton.setParams({
        text: t("buy_subscription.go_to_payment"),
      });
      tg.onEvent("mainButtonClicked", goToPaymentLink);
      tg.MainButton.enable();

      return () => {
        tg.offEvent("mainButtonClicked", goToPaymentLink);
      };
    }

    if (isPaymentLinkLoading) {
      tg.MainButton.show();
      tg.MainButton.setParams({
        text: t("common.loading"),
      });
      tg.MainButton.disable();

      return () => {
        tg.MainButton.hide();
      };
    }

    tg.onEvent("mainButtonClicked", getPaymentLink);
    return () => {
      tg.offEvent("mainButtonClicked", getPaymentLink);
    };
  }, [getPaymentLink, tg, paymentLink, isPaymentLinkLoading]);

  useEffect(() => {
    if (price) {
      tg.MainButton.show();
      tg.MainButton.setParams({
        text: t("buy_subscription.buy_for", {
          price,
        }),
      });
    } else {
      tg.MainButton.hide();
    }
  }, [price]);

  const getPageData = useCallback(async () => {
    try {
      setIsLoading(true);
      setError("");
      await getPremiumChats();
      await getMySubscriptions();
      await getSubscriptionsList();
      await getInvitedMembers();
    } catch (error) {
      console.log(error);
      error instanceof Error && setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getPageData();
  }, []);

  return (
    <BackButtonProvider>
      <section>
        <MainHeadline>{t("buy_subscription.headline")}</MainHeadline>
        {(invitedMembers.length || "") && (
          <>
            <h2 className="text-dark-200 text-h4 text-left mb-3">
              {t("buy_subscription.invited_members")}
            </h2>
            <div className="flex gap-2 flex-col mb-5">
              {invitedMembers.map((item) => (
                <div className="w-full flex justify-between flex-wrap" key={item.chat}>
                  <div className="w-full justify-between flex">
                    <p>{item.chat}</p>
                    <p>{`${item.usersCount} ${t("common.members")}`}</p>
                  </div>
                  {30 > item.usersCount && (
                    <p className="text-xs font-bold text-left">
                      {t("buy_subscription.invited_members_info", {
                        leastMembersCount: 30 - item.usersCount,
                      })}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
        {(myManualMessagingChats.length || "") && (
          <>
            <h2 className="text-dark-200 text-h4 text-left mb-3">
              {t("buy_subscription.subscription_for_new_members")}
            </h2>
            <div className="flex gap-2 flex-col mb-5">
              {myManualMessagingChats.map((item) => (
                <div className="w-full flex justify-between" key={item.id}>
                  <p>{premiumChats.find((chat) => chat.id === item.id)?.name}</p>
                  <p>{formatDate(new Date(item.expireAt))}</p>
                </div>
              ))}
            </div>
          </>
        )}
        {(mySubscriptions.length || "") && (
          <>
            <h2 className="text-dark-200 text-h4 text-left mb-3">
              {t("buy_subscription.my_subscriptions")}
            </h2>
            <div className="flex gap-2 flex-col mb-5">
              {mySubscriptions.map((item) => (
                <div className="w-full flex justify-between" key={item.id}>
                  <p>{premiumChats.find((chat) => chat.id === item.id)?.name}</p>
                  <p>
                    {item?.expireAt && item?.expireAt > Date.now()
                      ? formatDate(new Date(item.expireAt))
                      : t("home.buy_subscription")}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}

        {isLoading && <p className="mb-4">{t("common.loading")}</p>}

        <ListWrapper>
          {subscriptionList.map((item) => (
            <PaymentPart
              isActive={activeSubscription === item._id}
              onClick={() => setActiveSubscription(item._id)}
              key={item._id}
              {...item}
            />
          ))}
        </ListWrapper>
        {!activeSubscription && (
          <p className="text-left text-body-1 text-secondary-300">
            {t("buy_subscription.caption")}
          </p>
        )}
        {activeSubscription && (
          <>
            {(isLoading || "") && <p>{t("common.loading")}</p>}
            {(error || "") && <p className="text-red-500">{error}</p>}
            <div className="flex justify-between items-center mb-5">
              <h2 className="text-h4">{t("buy_subscription.price")}</h2>
              <span className="text-h2">${price}</span>
            </div>
            {(price || "") && (
              <div className="flex justify-center">
                <img
                  width={30}
                  height={30}
                  className="w-7.5 h-7.5"
                  src={emojiIcon}
                  alt="continue"
                />
              </div>
            )}
          </>
        )}
      </section>
    </BackButtonProvider>
  );
};

import { ReactNode, useCallback, useEffect } from "react";
import { useTelegram } from "../hooks/useTelegram";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../hooks/useNotificationContext";

interface BackButtonProviderProps {
  children: ReactNode;
  onBack?: () => void;
}

export const BackButtonProvider = ({ children, onBack }: BackButtonProviderProps) => {
  const navigation = useNavigate();
  const { hasMessage } = useNotificationContext();
  const { tg } = useTelegram();

  const goBack = useCallback(() => {
    navigation(-1);
  }, [navigation]);

  useEffect(() => {
    tg.ready();
    tg.BackButton.show();

    // @ts-ignore
    tg.onEvent("backButtonClicked", onBack || goBack);

    return () => {
      // @ts-ignore
      tg.offEvent("backButtonClicked", onBack || goBack);
      tg.BackButton.hide();
    };
  }, [tg, goBack, onBack]);
  return <div className={hasMessage ? "blur-5" : ""}>{children}</div>;
};

import { createContext, Context, useState } from "react";

interface INotificationContext {
  success: string;
  error: string;
  hasMessage: boolean;
  setSuccess: (message: string) => void;
  setError: (message: string) => void;
  closeNotifications: () => void;
}

interface INotificationContextProviderProps {
  children: React.ReactNode;
}

export const NotificationContext = createContext<INotificationContext | null>(
  null
) as Context<INotificationContext>;

export const NotificationContextProvider = ({ children }: INotificationContextProviderProps) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const closeNotifications = () => {
    setSuccess("");
    setError("");
  };

  const hasMessage = !!(success || error);

  const value: INotificationContext = {
    success,
    error,
    hasMessage,
    setSuccess,
    setError,
    closeNotifications,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

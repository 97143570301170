import { useTranslation } from "react-i18next";
import { ListWrapper } from "../../primitives/wrappers/ListWrapper";
import { useCallback, useEffect, useState } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import classNames from "classnames";
import {
  getFilterUsersQuery,
  getPremiumChatsQuery,
  getUserPremiumChatsQuery,
} from "../../../services/web-app";
import { IUser } from "../AdminPanel";
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from "../../../assets/icons/icons";
import { shortcutUsername } from "../../../utils/getUsername";
import { useQuery } from "@tanstack/react-query";

interface IChat {
  name: string;
  id: string;
}

export const GiveSubscription = () => {
  const { t } = useTranslation();
  const { tg, sendData } = useTelegram();

  const [userId, setUserId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [premiumChats, setPremiumChats] = useState<IChat[]>([]);
  const [userPremiumChats, setUserPremiumChats] = useState<string[]>([]);
  const [searchGroupTerm, setSearchGroupTerm] = useState<string>("");
  const [groupSearchResults, setGroupSearchResults] = useState<IChat[]>([]);
  const [enableAutopost, setEnableAutopost] = useState(true);


  const handleUserSelection = (userId: number) => {
    setUserId(userId);
  };

  const sendUserId = useCallback(() => {
    try {
      if (!userId) return;
      sendData({
        userId,
        premiumChats: userPremiumChats,
        type: "permission",
        adminPanel: true,
        enableAutopost,
      });
    } catch (error) {
      console.log(error);
    }
  }, [userId, sendData, userPremiumChats, enableAutopost]);

  const getUsers = useCallback(async (): Promise<IUser[]> => {
    try {
      if (searchTerm.length < 1) return [];
      setUserId(null);
      const res = await getFilterUsersQuery(shortcutUsername(searchTerm), false);

      if (res.ok) {
        const data = await res.json();
        return data;
      }

      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [searchTerm]);

  const { data: searchResults, isLoading: isUsersLoading } = useQuery({
    queryKey: ["users", searchTerm],
    queryFn: getUsers,
    enabled: !!shortcutUsername(searchTerm),
  });

  const getPremiumChats = useCallback(async () => {
    try {
      if (!tg?.initDataUnsafe?.user?.id) return;
      setIsLoading(true);
      setError("");
      const chatsResponse = await getPremiumChatsQuery();
      if (!chatsResponse.ok) throw new Error("Failed to fetch premiun chats");
      const chatsData = await chatsResponse.json();

      setPremiumChats(chatsData);
    } catch (error) {
      console.log(error);
      error instanceof Error && setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getUserPremiumChats = useCallback(async (userId: number) => {
    try {
      setIsLoading(true);
      setError("");
      const userResponse = await getUserPremiumChatsQuery(userId);
      if (!userResponse.ok) throw new Error("Failed to fetch premium chats");
      const userData = await userResponse.json();

      setUserPremiumChats(userData);
    } catch (error) {
      console.log(error);
      error instanceof Error && setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const isSelectedPremiumChat = (id: string) => {
    return userPremiumChats.find((chat) => chat === id);
  };

  const toggleUserPremiumChat = (id: string) => {
    if (!userId) return;
    const selectedPremiumChat = isSelectedPremiumChat(id);

    if (selectedPremiumChat) {
      setUserPremiumChats(userPremiumChats.filter((chat) => chat !== id));
    } else {
      setUserPremiumChats([...userPremiumChats, id]);
    }
  };

  const getUserName = (user: IUser | undefined) => {
    if (!user) return "";
    return user.username || user.first_name;
  };

  useEffect(() => {
    if (!userId || userPremiumChats.length === 0) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
      tg.MainButton.setText(t("admin_panel.give_subscription"));
    }
  }, [userId, tg, userPremiumChats]);

  useEffect(() => {
    tg.onEvent("mainButtonClicked", sendUserId);
    return () => {
      tg.offEvent("mainButtonClicked", sendUserId);
    };
  }, [sendUserId, tg]);

  useEffect(() => {
    getPremiumChats();
  }, []);

  useEffect(() => {
    if (!userId) return;
    getUserPremiumChats(userId);
  }, [userId]);

  useEffect(() => {
    if (!searchGroupTerm) return setGroupSearchResults(premiumChats);

    const filteredChats = premiumChats.filter((chat) =>
      chat.name.toLowerCase().includes(searchGroupTerm.toLowerCase())
    );

    setGroupSearchResults(filteredChats);
  }, [searchGroupTerm, premiumChats]);

  return (
    <>
      <ListWrapper classes="mb-6.5">
        <h2 className="text-dark-200 text-h4 text-left">{t("admin_panel.give_subscription")}</h2>
        <input
          className="grow text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
          type="text"
          placeholder={t("admin_panel.toggle_input_placeholder")}
          value={searchTerm}
          onChange={({ target }) => setSearchTerm(target.value)}
        />
        <ul className="flex flex-col gap-1.5">
          {(searchResults || []).map((user) => (
            <li
              className={classNames(
                "grow rounded-xl px-3 py-2.5 cursor-pointer outline-none",
                userId === user.id
                  ? "bg-primary-100 bg-gradient-to-b from-primary-100 to-primary-300 text-light-100"
                  : "bg-light-200 text-primary-100 "
              )}
              key={user.id}
              onClick={() => handleUserSelection(user.id)}
            >
              {getUserName(user)}
            </li>
          ))}
        </ul>
        {(isLoading || isUsersLoading || "") && <p>{t("common.loading")}</p>}
      </ListWrapper>
      {userId && (
        <ListWrapper classes="mb-6.5">
          <h2 className="text-dark-200 text-h4 text-left">{t("admin_panel.group")}</h2>
          <input
            className="grow text-dark-100 bg-light-200 rounded-xl px-3 py-2.5 cursor-pointer outline-none"
            disabled={isLoading}
            type="text"
            placeholder={t("admin_panel.choose_group")}
            value={searchGroupTerm}
            onChange={({ target }) => setSearchGroupTerm(target.value)}
          />
          <ul className="flex flex-col gap-1.5">
            {groupSearchResults.map((chat) => (
              <li
                className={classNames(
                  "grow rounded-xl px-3 py-2.5 cursor-pointer outline-none",
                  isSelectedPremiumChat(chat.id)
                    ? "bg-primary-100 bg-gradient-to-b from-primary-100 to-primary-300 text-light-100"
                    : "bg-light-200 text-primary-100 "
                )}
                key={chat.id}
                onClick={() => toggleUserPremiumChat(chat.id)}
              >
                {chat.name}
              </li>
            ))}
          </ul>
          <div
            className={classNames("flex gap-2.5 cursor-pointer")}
            onClick={() => setEnableAutopost(!enableAutopost)}
          >
            {enableAutopost ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
            <p className="text-subtitle-1 text-dark-100">{t("admin_panel.enable_autopost")}</p>
          </div>
          {(isLoading || "") && <p>{t("common.loading")}</p>}
          {(error || "") && <p className="text-red-500">{error}</p>}
        </ListWrapper>
      )}
    </>
  );
};

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { PostForm } from "./components/PostForm/PostForm";
import { useEffect } from "react";
import { useTelegram } from "./hooks/useTelegram";
import { Home } from "./components/Home/Home";
import { SubscriptionList } from "./components/Payment";
import { AdminPanel } from "./components/AdminPanel/AdminPanel";
import { AddToGroupInfo } from "./components/AddToGroupInfo/AddToGroupInfo";
import { useTranslation } from "react-i18next";
import { SuccessNotification } from "./components/primitives/notifications/SuccessNotification";
import { NotificationContextProvider } from "./context/NotificationContext";
import { ErrorNotification } from "./components/primitives/notifications/ErrorNotification";
import { updateTimezone } from "./services/web-app";
import { Rules } from "./components/Rules/Rules";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SuccessfulPayment } from "./components/SuccessfulPayment/SuccessfulPayment";

function App() {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const { tg } = useTelegram();

  useEffect(() => {
    tg.ready();
    window.Telegram.WebApp.expand();
    tg.MainButton.setParams({
      color: "#8569C1",
    });
  }, [tg]);

  useEffect(() => {
    updateTimezone();
    if (!tg?.initDataUnsafe || !tg.initDataUnsafe.user?.language_code) return;

    tg.CloudStorage.getItem("language", (_error: Error, language: string) => {
      if (language) {
        changeLanguage(language);
        return;
      }

      if (!tg.initDataUnsafe || !tg.initDataUnsafe?.user?.language_code) return;
      changeLanguage(tg.initDataUnsafe.user.language_code);
    });
  }, []);

  const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    {
      path: "/post-form",
      element: <PostForm />,
    },
    {
      path: "/subscription",
      element: <SubscriptionList />,
    },
    {
      path: "/admin-panel",
      element: <AdminPanel />,
    },
    {
      path: "/add-to-group",
      element: <AddToGroupInfo />,
    },
    {
      path: "/rules",
      element: <Rules />,
    },
    {
      path: "/success-payment",
      element: <SuccessfulPayment />,
    },
  ]);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationContextProvider>
        <RouterProvider router={router} />
        <SuccessNotification />
        <ErrorNotification />
      </NotificationContextProvider>
    </QueryClientProvider>
  );
}

export default App;
